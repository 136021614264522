/*-----------------------------------------------------------------------------------

	content.less

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/


#content {
	position: relative;
	overflow: hidden;
	background-color: #FFF;

	p {
		line-height: @line-height-content;
	}

	.content-wrap {
		position: relative;
		padding: 50px 0;
	}

	.container {
		position: relative;
	}
}

.grid-container {
	position: relative;
	overflow: hidden;
}

body:not(.device-touch) .grid-container {
	-webkit-transition: height .4s linear;
	-o-transition: height .4s linear;
	transition: height .4s linear;
}

