#copyrights {
    border-bottom: 20px solid #fecc00;

}

#copyrights2 {
    padding: 30px 0;
}
#copyrights2 img {
    opacity: 0.7;
    margin-left: 10px;
    margin-top: -5px;
   -webkit-filter: grayscale(100%);
-moz-filter: grayscale(100%);
-o-filter: grayscale(100%);
-ms-filter: grayscale(100%);
filter: grayscale(100%);
}
#copyrights2 img:hover {
    opacity:1;
    -webkit-filter: grayscale(0%);
-moz-filter: grayscale(0%);
-o-filter: grayscale(0%);
-ms-filter: grayscale(0%);
filter: grayscale(0%);
}
#kontakt {
    color: #bcbcbc!important;
    border-color: #bcbcbc;
    margin-left: 0!important;
}
#page-title {
    background-color: #fecc00;
    border-bottom: 5px solid #fecc00;
    padding: 10px 0;
    
    h1 {
     //   color: #fecc00;
    }
}


.dark #header-wrap:not(.not-dark) #primary-menu > ul > li.current > a, 
.dark #header-wrap:not(.not-dark) #primary-menu > .container > ul > li.current > a {
    color: #212121!important;
}

.dark #primary-menu:not(.not-dark) ul ul, {
    border-top-color: #fecc00!important;
}

.breadcrumb > li + li:before {
    color: #555;
}

.fancy-title h1, .fancy-title h2, .section .fancy-title h3, .fancy-title h4, .fancy-title h5, .fancy-title h6 {
    background-color: #F9F9F9;
    }
    
    #kontakt-form input, #kontakt-form textarea {
    margin: 8px 0;
    border-width: 1px;
    border-radius: 4px!important;
}
#kontakt-form input:focus, #kontakt-form textarea:focus {
    border-color: #d26f18;
}
#kontakt-form textarea {
    height: 162px;
}
.btn-kontakt {
    width: 100%;
}
.btn-primary {
    color: #ffffff;
    background-color: @theme-color;
    border-color: @theme-color;
}
.btn-primary:hover {
    background-color: #fecc00;
    color: #212121;
}

#eu-footer {
	margin-top:50px;
	font-size: 14px;
	color: #999;
	text-align: center;
	
	img {
		margin-right: 90px;
	}
	img:nth-of-type(3) {
		margin:0;
	}
	p {
		margin-top: 15px;
	}
}



@media only screen and (max-width: 991px) {
	
	#eu-footer img {
			margin: 0 20px 20px 20px;
	}

}

